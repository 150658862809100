import React from 'react';
import { graphql } from 'gatsby';
import erfaringBoxes2 from '../images/boxes/erfaringer_boxer_2.svg';
import erfaringBoxes3 from '../images/boxes/erfaringer_boxer_3.svg';
import Img from 'gatsby-image';
import styles from './Experience.module.scss';
import SEO from '../components/seo/SEO';
import Layout from '../components/layout/Layout';
import ListItem from '../images/boxes/listitem.svg';
import sectorStyles from './Sectors.module.scss';

export const query = graphql`
  query {
    boat: file(relativePath: { eq: "erfaring/boat.jpg" }) {
      childImageSharp {
        fluid {
          aspectRatio
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    hero: file(relativePath: { eq: "erfaring/hero.jpg" }) {
      childImageSharp {
        fluid {
          aspectRatio
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    platform: file(relativePath: { eq: "erfaring/platform.jpg" }) {
      childImageSharp {
        fluid {
          aspectRatio
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    coradia: file(relativePath: { eq: "erfaring/coradia.png" }) {
      childImageSharp {
        fluid {
          aspectRatio
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    explosion: file(relativePath: { eq: "erfaring/explosion.jpg" }) {
      childImageSharp {
        fluid {
          aspectRatio
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const ErfaringerPage = (props: any) => {
  const { data } = props;
  return (
    <>
      <Layout>
        <SEO
          title="Experience. Read about our history and our previous challenges.
"
        />
        <div className="grid_text_image grid_vertical_padding sectionVeryLong mb-48">
          <div>
            <h1 className={styles.welcomeText}>
              <span className={styles.avoidWrap}>HYEX Safety</span>{' '}
            </h1>
            <p className={styles.descriptionText}>
              Since starting in June 2020, HYEX Safety has delivered more than 100 risk assessments 
              in the field hydrogen and ammonia safety. Below are some of the notable aspects of our 
              portfolio:
            </p>
            <div className={sectorStyles.descriptionText}>
              <div className={sectorStyles.projectListContainer}>
                <div className={sectorStyles.projectItem}>
                  <img className={sectorStyles.listItemImg} src={ListItem} alt="box" /> Hydrogen 
                  fuelled ships: We support several vessel developers in their effort to design and 
                  approve zero emission vessels fuelled with hydrogen or hydrogen carriers. Such 
                  projects will need to follow an alternative design process documenting by explosion 
                  risk assessments that the vessel is equally safe as a similar modern conventional 
                  vessel. Since 2020 we have supported more than 20 vessel projects in 5 countries, 
                  including car and passenger ferries, cargo vessels, cruise ships, a yacht, crew 
                  transfer vessels and a private leisure boat.
                </div>
                <div className={sectorStyles.projectItem}>
                  <img className={sectorStyles.listItemImg} src={ListItem} alt="box" /> Hydrogen 
                  production and storage technologies: Through various projects related to hydrogen 
                  production facilities and systems we have developed a significant understanding of 
                  possible safety challenges of various electrolyser technologies as well as storage 
                  solutions. We are happy to see that facilities we have supported with risk 
                  assessments for approval processes have started production, or will soon, in as 
                  diverse fields as PtX, green metal production, green ammonia production, 
                  hydrogen-to-homes and more.
                </div>
                <div className={sectorStyles.projectItem}>
                  <img className={sectorStyles.listItemImg} src={ListItem} alt="box" /> Ammonia Risk 
                  Assessment: Ammonia is projected to be an important hydrogen carrier within the 
                  maritime. HYEX is a partner in the ammonia fuel bunkering network and responsible 
                  for safety studies to help developing a network of green ammonia bunkering terminals. 
                  Safety studies for ammonia facilities as well as ammonia fuelled vessels are also 
                  performed.
                </div>
                <div className={sectorStyles.projectItem}>
                  <img className={sectorStyles.listItemImg} src={ListItem} alt="box" /> Siting studies: 
                  While the majority of facility siting studies are performed for clients in Norway and 
                  Scandinavia, we also perform studies for clients elsewhere.
                </div>
                <div className={sectorStyles.projectItem}>
                  <img className={sectorStyles.listItemImg} src={ListItem} alt="box" /> Expertise: Our 
                  goal is to provide highly competent advice reflecting state-of-the-art knowledge 
                  within hydrogen and ammonia safety. At the same time we want to give advice in an 
                  efficient way. To achieve both, we use a combination of spreadsheet tools and 
                  comprehensive computational fluid dynamics (CFD) combined with expert knowledge. We 
                  regularly share knowledge and experience by publishing work in scholarly journals. A 
                  selection of relevant articles is found at the bottom of this page. We also engage in 
                  research and development initiatives such as the Ammonia Fuel Bunkering Network 
                  project and the HYDROGENi research centre. HYEX Safety is also represented in the 
                  advisory board of the ELVHYS LH2 project and also IMO interim guidance development 
                  for hydrogen vessels representing the NGO ZESTAs.
                </div>
              </div>
            </div>
          </div>
          <div className={styles.imageContainer}>
            <figure className="headerImageHorizontal fullWidthMobile">
              <Img fluid={data.coradia.childImageSharp.fluid} alt="Train" />
            </figure>
            <div className={styles.figcaption}>
              <p>
                CFD-geometry of Coradia iLint hydrogen train. Photo: Alstom.
              </p>
            </div>
          </div>
        </div>
        <div className={styles.boxContainer2}>
          <img className={styles.boxIcon} src={erfaringBoxes2} alt="boxes" />
        </div>
        <div className="grid_text_image grid_vertical_padding sectionShort mb-48">
          <div>
            <h1 className={styles.welcomeText}>Fast and reliable computational fluid dynamics</h1>
            <p className={styles.descriptionText}>
              The work career of Olav Roald Hansen, Founder of HYEX Safety, has focused on the
              development and use of the CFD-model FLACS.
            </p>
          </div>
          <div>
            <figure className="headerImageHorizontal fullWidthMobile">
              <Img fluid={data.hero.childImageSharp.fluid} alt="Olav Roald Hansen on stage" />
            </figure>
          </div>
        </div>
        <div className="grid_text_image grid_vertical_padding sectionVeryLong">
          <div>
            <h1 className={styles.welcomeText}>
              <span className={styles.avoidWrap}>CMR and Gexcon</span>{' '}
              <span className={styles.avoidWrap}>(1993-2012)</span>
            </h1>
            <p className={styles.descriptionText}>
              In 1993, Olav joined CMR (Gexcon) and the team developing the FLACS software tool. He
              quickly became responsible for validating and calibrating FLACS-models against 100s of
              large-scale experiments, and developed sub-models, e.g. for flame acceleration from
              sub-grid objects, effect of water deluge and mixing rules for multi-component gas
              mixtures and inert gases. He was in charge of modelling activities towards various
              phases of the large-scale BFETS explosion test projects. With Gexcon, he developed a
              CFD-based QRA-approach. Validation-based user guidelines for FLACS were established
              and he developed a 3-days FLACS training course 1997, used to train FLACS-users for
              the next 10-15 years. As R&D Director from 2001-2008, he was responsible for the
              development and global sale of FLACS, with significant interaction with engineers from
              50+ companies using FLACS globally. He organized industry seminars across Europe and
              held hands-on FLACS workshops for MSc students 10 years in a row at Leeds University.
              He was in charge of development projects to study transformer explosions, oil mist
              explosions, develop LNG pool model, hydrogen dispersion/explosion tests and dust
              explosion models, and was active in the HySafe project and IEA expert groups from
              2004-2012 with significant interaction with global expertise. He was also involved in
              various dispersion projects with US agencies (DTRA/DHS/EPA) related to toxic industry
              chemicals. In 2008, at the onset of the global financial crisis, Olav started Gexcon
              US, which he led until returning to Norway 2010 to become Gexcon Product Director.
            </p>
          </div>
          <div className={styles.imageContainer_2}>
            <figure className="headerImageHorizontal fullWidthMobile">
              <Img fluid={data.platform.childImageSharp.fluid} alt="Platform on fire" />
            </figure>
          </div>
        </div>
        <div className="grid_text_image grid_vertical_padding sectionVeryLong">
          <div>
            <h1 className={styles.welcomeText}>
              <span className={styles.avoidWrap}>GL Noble Denton</span>{' '}
              <span className={styles.avoidWrap}>(2012-2014)</span>{' '}
            </h1>
            <p className={styles.descriptionText}>
              In 2012 Olav joined GL (now part of DNV) and built a small CFD consulting team working
              closely with UK colleagues, including the team at Spadeadam large-scale test site.
              CFD-studies for clients at three continents were performed. With the help of
              large-scale experiments modelling approaches were developed e.g. for DDT/detonation
              simulations and blast from BLEVEs, both beyond published FLACS-capabilities.
            </p>
          </div>
          <div className={styles.imageContainer}>
            <figure className="headerImageHorizontal fullWidthMobile">
              <Img fluid={data.explosion.childImageSharp.fluid} alt="Chemical explosion" />
            </figure>
          </div>
        </div>
        <div className="grid_text_image grid_vertical_padding sectionVeryLong mb-48">
          <div>
            <h1 className={styles.welcomeText}>
              <span className={styles.avoidWrap}>Lloyd's Register</span>{' '}
              <span className={styles.avoidWrap}>(2014-2020)</span>
            </h1>
            <p className={styles.descriptionText}>
              In 2014, Olav joined the risk management part of LR as a Senior Principal Consultant
              with responsibility for explosion studies. Prior to and through the 2014-2016 oil
              crisis, he was involved in numerous oil and gas projects, including most phases of,
              and platforms at, the huge Johan Sverdrup field, opened 2019. From 2016, he gradually
              focused more on hydrogen safety studies. Increasingly, Olav cooperated with the
              maritime division of LR related to hydrogen, being involved in ongoing hydrogen
              projects, and developing a training course for their specialist. Between 2016 and 2020, 
              he was been involved in 30-40 different hydrogen safety consulting projects and R&D
              activities.
            </p>
            <p className={styles.descriptionText}>
              Through his career, Olav has enjoyed good access to large-scale experiments through
              R&D sponsors, cooperation partners or colleagues performing testing. He never wasted
              an opportunity to simulate large-scale experiments, often prior to getting access to
              test results (i.e. blind predictions). The combination of model predictions and access
              to results from experiments has made it possible to gain unique insights and
              understanding of explosion and dispersion phenomena, and how to model such scenarios
              quickly and with good precision.
            </p>
          </div>
          <div className={styles.imageContainer}>
            <figure className="headerImageHorizontal fullWidthMobile">
              <Img fluid={data.boat.childImageSharp.fluid} alt="Ship" />
            </figure>
          </div>
        </div>
        <div className={`grid_vertical_padding ${styles.articleTitle}`}>
          Selected articles and works early for their time:
        </div>
        <div className={`grid_vertical_padding ${styles.articleContainer}`}>
          <div className={styles.articleColumn}>
            <div className={styles.article}>
              <a
                href="https://doi.org/10.1016/j.psep.2023.04.021"
                target="_blank"
                rel="noopener noreferrer"
                title="2023 - CFD-modelling of large-scale LH2 release and explosion experiments."
              >
                2023 - CFD-modelling of large-scale LH2 release and explosion experiments.
              </a>
            </div>
            <div className={styles.article}>
              <a
                href="https://doi.org/10.1016/j.ijhydene.2019.09.060"
                target="_blank"
                rel="noopener noreferrer"
                title="2020 - Approach for efficient and accurate CFD modelling of LH2 releases."
              >
                2020 - Approach for efficient and accurate CFD modelling of LH2 releases.
              </a>
            </div>
            <div className={styles.article}>
              <a
                href="https://doi.org/10.3303/CET1977118"
                target="_blank"
                rel="noopener noreferrer"
                title="2019 - Modelling of blast from water vapor in molten metal."
              >
                2019 - Modelling of blast from water vapor in molten metal.
              </a>
            </div>
            <div className={styles.article}>
              <a
                href="https://doi.org/10.3303/CET1648034"
                target="_blank"
                rel="noopener noreferrer"
                title=""
              >
                2016 - Article on blast modelling from BLEVEs.
              </a>
            </div>
            <div className={styles.article}>
              <a
                href="https://doi.org/10.1016/j.jlp.2016.06.001"
                target="_blank"
                rel="noopener noreferrer"
                title=""
              >
                2016 - Article on improved approaches for load prediction onto equipment using CFD.
              </a>
            </div>
            <div className={styles.article}>
              <a
                href="https://doi.org/10.1016/j.jlp.2014.11.005"
                target="_blank"
                rel="noopener noreferrer"
                title=""
              >
                2015 - Efficient approach to model DDT, detonations and shock-waves with accuracy
                for industry applications.
              </a>
            </div>
            <div className={styles.article}>
              <a
                href="https://doi.org/10.1016/j.jlp.2012.07.006"
                target="_blank"
                rel="noopener noreferrer"
                title=""
              >
                2013 - Equivalent stoichiometric clouds - article describing concept widely used for
                risk assessment work, simulations of ignited large-scale dispersion experiments are
                also presented.
              </a>
            </div>
          </div>
          <div className={styles.articleColumn}>
            <div className={styles.article}>
              <a
                href="https://doi.org/10.1016/j.jlp.2010.08.005"
                target="_blank"
                rel="noopener noreferrer"
                title=""
              >
                2010 - Validation against LNG model evaluation protocol, FLACS first model to be
                accepted by DoT PHMSA at alternative tool for NFPA-59A.
              </a>
            </div>
            <div className={styles.article}>
              <a
                href="https://doi.org/10.1016/j.jlp.2005.06.005"
                target="_blank"
                rel="noopener noreferrer"
                title=""
              >
                2006 - Dust explosion modelling with CFD.
              </a>
            </div>
            <div className={styles.article}>
              <a
                href="https://doi.org/10.1175/BAMS-87-12-1713"
                target="_blank"
                rel="noopener noreferrer"
                title=""
              >
                2006 - Blind predictions of tracer gas releases at Manhattan.
              </a>
            </div>
            <div className={styles.article}>
              <a
                href="https://www.researchgate.net/publication/259078926_Dust_explosion_venting_and_suppression_of_conventional_spray_dryers"
                target="_blank"
                rel="noopener noreferrer"
                title=""
              >
                2004 - Blind predictions of spray dryer dust explosions using gas explosion model
                calibrated for dust.
              </a>
            </div>
            <div className={styles.article}>
              <a
                href="https://www.researchgate.net/publication/259077554_Oil_Mist_Explosion_Experiments"
                target="_blank"
                rel="noopener noreferrer"
                title=""
              >
                2002 - Oil mist explosion article describing important differences between gas and
                mist explosions.
              </a>
            </div>
            <div className={styles.article}>
              <a
                href="https://www.researchgate.net/publication/45688553_Suppression_of_secondary_explosions_in_transformer_rooms"
                target="_blank"
                rel="noopener noreferrer"
                title=""
              >
                2002 - Transformer explosion article.
              </a>
            </div>
            <div className={styles.article}>
              <a
                href="https://www.researchgate.net/publication/341778396_Can_CFD-Explosion_Simulations_Improve_Safety_and_Provide_Cost_Efficient_Solutions_Onshore"
                target="_blank"
                rel="noopener noreferrer"
                title=""
              >
                2000 - Can CFD-Explosion Simulations Improve Safety and Provide Cost Efficient Solutions Onshore?
              </a>
            </div>
            <div className={styles.article}>
              <a
                href="https://www.researchgate.net/profile/Olav_Hansen/publications"
                target="_blank"
                rel="noopener noreferrer"
                title=""
              >
                More at ResearchGate
              </a>
            </div>
          </div>
        </div>
        <div className={styles.boxContainer2}>
          <img className={styles.boxIcon} src={erfaringBoxes3} alt="boxes" />
        </div>
      </Layout>
    </>
  );
};

export default ErfaringerPage;
